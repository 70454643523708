import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "@firebolt-dev/jsx/jsx-runtime";
import {cloneElement} from 'react';
import {useMDXComponents} from 'firebolt';
const MDXLayout = function MDXWrapper({children}) {
  const components = useMDXComponents();
  return cloneElement(children, {
    components
  });
};
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h1: "h1",
    p: "p",
    pre: "pre",
    span: "span",
    ...props.components
  }, {Meta} = _components;
  if (!Meta) _missingMdxReference("Meta", true);
  return _jsxs(_Fragment, {
    children: [_jsx(Meta, {
      title: "Getting Started: Cookies",
      description: "Learn how to use cookies in a new Firebolt app."
    }), "\n", _jsx(_components.h1, {
      children: "Cookies"
    }), "\n", _jsx(_components.p, {
      children: "Firebolt makes it easier to work with cookies by making them reactive from anywhere in your app."
    }), "\n", _jsx(_components.p, {
      children: "If you modify a cookie on the server or in another component, any other components observing the cookie will instantly update."
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      title: "components/AuthWidget.js",
      lineNumbers: true,
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "import"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " { useCookie } "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "from"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " 'firebolt'"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "export"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " function"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " AuthWidget"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "() {"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  const"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " ["
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: "token"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ", "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: "setToken"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "] "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " useCookie"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'authToken'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ")"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  return"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " token "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "?"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: "Profile"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " /> "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: ":"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: "LoginButton"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " />"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsxs(_components.p, {
      children: ["In this example, any changes to the ", _jsx(_components.code, {
        children: "authToken"
      }), " cookie (eg after logging in or out) will toggle the auth widget component between showing the current users profile or showing a login button."]
    }), "\n", _jsxs(_components.p, {
      children: ["To learn more about cookies, check out the ", _jsx(_components.a, {
        href: "/docs/ref/useCookie",
        children: "useCookie"
      }), " and ", _jsx(_components.a, {
        href: "/docs/ref/context",
        children: "Context"
      }), " docs."]
    })]
  });
}
export default function MDXContent(props = {}) {
  return _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  });
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
